import React, { FC, useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { useTranslation, withTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TagManager from 'react-gtm-module';
import theme from 'assets/styles';
import store from './services/store';
import { Routes } from './Routes';
import GlobalStyles from 'assets/styles/Global';
import './i18n';
import 'dayjs/locale/fr';
import 'moment/locale/fr-ch';
import { getUserLanguage, setAntDLocale } from 'utils/helpers';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import PWAPrompt from 'react-ios-pwa-prompt';
import './assets/fonts/fonts.css';
import { Router } from 'react-router-dom';
import { history } from 'utils/history';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);

const language = getUserLanguage();
dayjs.locale(language.lng) && moment.locale(language.lng);

const tagManagerArgs = {
  gtmId: 'GTM-KGCD776',
};
TagManager.initialize(tagManagerArgs);
const isProduction = process.env.NODE_ENV === 'production';

const App: FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    try {
      const newPWA = localStorage.getItem('newPWA');
      if (newPWA === null) {
        localStorage.removeItem('iosPwaPrompt');
        localStorage.setItem('newPWA', '1');
      }
    } catch (error) {
      console.error('Could not access localStorage:', error);
    }
  }, []);
  return (
    <>
      <PWAPrompt
        copyTitle={t('copyTitle')}
        copyBody={t('copyBody')}
        copyShareButtonLabel={t('copyShareButtonLabel')}
        copyAddHomeButtonLabel={t('copyAddHomeButtonLabel')}
        copyClosePrompt={t('copyClosePrompt')}
      />
      <CacheBuster currentVersion={version} isEnabled={isProduction} isVerboseMode={false} metaFileDirectory={'.'}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <GlobalStyles />
            <ConfigProvider locale={setAntDLocale()}>
              <Elements stripe={stripePromise}>
                <Router history={history}>
                  <Routes />
                </Router>
              </Elements>
            </ConfigProvider>
          </Provider>
        </ThemeProvider>
      </CacheBuster>
    </>
  );
};

export default withTranslation()(App);
